
// JQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Library
require('./bootstrap');
require('slick-slider')

// Components
import('./components/sliders');

// Pages
import('./pages/homepage')

$('.--make__an__appointment').css('visibility', 'visible');
appointmentLoad();
function appointmentLoad () {
    setTimeout(function(){
        $('.--make__an__appointment').css('opacity', '1');
    },1500);
}

$('.--make__an__appointment').on('click', function(){
    $(this).toggleClass('active');
});